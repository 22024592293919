
import { Component, Vue, Prop } from "vue-property-decorator";
import PlotWidget from "@/components/widgets/PlotWidget.vue";
import "@/plugins/swiper.ts";
import { IPlot } from "@/models/Plots";

@Component({ components: { PlotWidget } })
export default class ResumePlots extends Vue {
  @Prop({ type: Array, required: true })
  protected data!: IPlot[];

  @Prop({ type: Boolean, default: false })
  protected swLoop?: boolean;

  @Prop({ type: Boolean, default: true })
  protected swCenteredSlides?: boolean;

  @Prop({ type: Number, default: 0 })
  protected swSpaceBetween?: number;

  @Prop({ type: Boolean, default: false })
  protected swNavigation?: boolean;

  @Prop({ type: Boolean, default: false })
  protected swPagination?: boolean;

  @Prop({ type: Boolean, default: false })
  protected loading?: boolean;

  @Prop({ type: Boolean, default: false })
  protected isAlbroksa?: boolean;

  protected swiperOption = {
    loop: this.swLoop,
    autoHeight: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 10,
    mousewheel: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  protected get dataPlot() {
    return !this.isAlbroksa ? this.data : [this.data[0]];
  }
}
