
import { Component, Vue, Prop } from "vue-property-decorator";
import ImageService from "@/services/ImageService";

interface BanksLists {
  name: string;
}

@Component
export default class Partners extends Vue {
  protected banksList: BanksLists[] = [
    { name: "santander" },
    { name: "kutxabank" },
    { name: "sabadell" },
    { name: "bankinter" },
    { name: "targobank" },
    { name: "bankia" },
    { name: "deutsche" },
    { name: "abanca" },
    { name: "liberbank" },
    { name: "uci" },
    { name: "evo" },
    { name: "ibercaja" },
    { name: "myinvestor" },
  ];

  @Prop({ type: String, required: true })
  protected title!: string;

  @Prop({ type: Boolean, default: false })
  protected dark?: boolean;

  @Prop({ type: String, default: null })
  protected logoColor?: string;

  @Prop({ type: String, default: null })
  protected bgColor?: string;

  @Prop({ type: String, default: "center" })
  protected justify?: string;

  get cmpBgColor(): string | null {
    return this.dark
      ? "bg-snowDrift"
      : this.bgColor
      ? `bg-${this.bgColor}`
      : null;
  }

  get logosJustify(): string {
    if (this.justify && ["left", "right"].includes(this.justify))
      return `justify-${this.justify}`;
    return "justify-center";
  }

  get imgPath(): string {
    return ImageService.cdnImage("assets/logos-banks-mortgages", {
      height: 1030,
    });
  }
}
