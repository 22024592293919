
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import DashboardMortgages from "@/components/dashboard/DashboardMortgages.vue";
import DashboardCommon from "@/components/dashboard/DashboardCommon.vue";
import { BUSINESS } from "@/constants";

@Component({
  computed: {
    ...mapGetters("auth", {
      useDashboard: "useDashboard",
      business: "getBusiness",
    }),
  },
  components: {
    DashboardMortgages,
    DashboardCommon,
  },
})
export default class Dashboard extends Vue {
  protected useDashboard!: boolean;
  protected business!: string[];

  protected get dashboardType(): string {
    let dashboardType = "dashboardCommon";

    if (
      this.business.length === 1 &&
      this.business.includes(BUSINESS.mortgages)
    ) {
      dashboardType = "dashboardMortgages";
    }

    return this.useDashboard ? dashboardType : "";
  }
}
