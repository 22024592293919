
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import HiboFloatingButton from "@/components/ui/HiboFloatingButton.vue";

@Component({
  components: { HiboFloatingButton },
})
export default class LeadFormButton extends Vue {
  @Prop({ type: String, default: "newLead" })
  protected labelKey?: string;

  @Emit("click")
  clicked() {}
}
