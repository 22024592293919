
import { Component, Vue } from "vue-property-decorator";
import { IPlot } from "@/models/Plots";
import { IAboutItem } from "@/models/Global";
import { mapGetters } from "vuex";
import ResumePlots from "@/components/dashboard/ResumePlots.vue";
import MortgagePartners from "@/components/mortgages/MortgagePartners.vue";
import LeadFormButton from "@/components/common/LeadFormButton.vue";
import InnerHeader from "@/components/common/InnerHeader.vue";
import LeadFormsWrapper from "@/components/common/LeadFormsWrapper.vue";
import AppraiserFormWrapper from "@/components/tools/appraiser-simulator/AppraiserFormWrapper.vue";

@Component({
  computed: {
    ...mapGetters("dashboard", {
      resumePlotsData: "getResumePlots",
      plotsLoading: "plotsLoading",
      isAlbroksa: "getIsAlbroksa",
    }),
    ...mapGetters("auth", {
      useResumePlots: "useResumePlots",
      business: "getBusiness",
    }),
    ...mapGetters("appraiser", {
      appraiserEnabled: "getEnabled",
    }),
  },
  components: {
    AppraiserFormWrapper,
    ResumePlots,
    LeadFormButton,
    MortgagePartners,
    InnerHeader,
    LeadFormsWrapper,
  },
})
export default class DashboardMortgages extends Vue {
  protected clientModal = false;
  protected valuationModal = false;

  protected resumePlotsData!: IPlot[];

  protected isAlbroksa!: boolean;

  protected useResumePlots!: boolean;
  protected business!: string[];

  protected aboutMortgages: IAboutItem[] = [
    {
      title: "getInTouch24",
      icon: "icons/twoPerson",
    },
    {
      title: "initialTailormadeFreeAdvice",
      icon: "icons/pencil",
    },
    {
      title: "bestConditionsAmongManyBanks",
      icon: "icons/percentage",
    },
    {
      title: "followUpUntillSignature",
      icon: "icons/burguer",
    },
  ];

  async mounted() {
    if (this.useResumePlots) {
      await this.$store.dispatch("dashboard/setResumePlots", this.business);
    }
  }
}
